import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import About from './components/pages/AboutUs/About';
import Home from './components/pages/Home/Home';
// import Tire from './components/pages/Tier/Tire';
// import Tube from './components/pages/Tube/Tube';
// import Linglong from './components/pages/Tires Brand/Linglong/Linglong'
// import PantuerTools from './components/pages/Home/PantuerTools';
// import Destone from './components/pages/Tires Brand/Destone/Destone'
// import Leao from './components/pages/Tires Brand/Leao/Leao';
// import Lionlord from './components/pages/Tires Brand/Alliagtor/Alliagtore';
// import Power from './components/pages/Tube brand/Power/Power';
// import Service from './components/pages/Tube brand/Service/Service';
// import Sobek from './components/pages/Tube brand/Sobek/Sobek';
// import Dongah from './components/pages/Tube brand/Dongah/Dongah';
// import Folad from './components/pages/Tube brand/Folad/Folad';
// import Nexen from './components/pages/Tube brand/Nexen/Nexen';
// import Painter from './components/pages/Tube brand/Painter/Painter';
// import Austone from './components/pages/Tires Brand/Austone/Austone';
import BounceLoader from 'react-spinners/BounceLoader'
import PunctureTools from './components/pages/PantuerTools/PunctureTools';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
function App() {
//  const [data, setData]=useState(null)
const [loading,setLoading]=useState(false);
useEffect(()=>{
  setLoading(true)
  setTimeout(()=>{
    // const fetchedData=fetcyDataFromAPI();
    // setData(fetchedData);
    setLoading(false)
  },2000)
},[])
  
  return (
       
    <>
       
        <div>
         
      {
        
        loading ? 
        
        <div className='loader'><BounceLoader 
        
        loading={loading}
        
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> </div>:(
        <Router>
        <Navbar />
            
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/about' element={<About />} />
              {/* <Route path='/tire' element={<Tire />} />
              <Route path='/tube' element={<Tube />} />
              <Route path='/pantuertools' element={<PantuerTools />} />
              <Route path='/destone' element={<Destone/>} />
              <Route path='/linglong' element={<Linglong />} />
              <Route path='/leao' element={<Leao />} />
              <Route path='/lionlord' element={<Lionlord />} />
              <Route path='/austone' element={<Austone />} />
              <Route path='/power' element={<Power />} />
              <Route path='/service' element={<Service />} />
              <Route path='/sobek' element={<Sobek />} />

              <Route path='/dongah' element={<Dongah />} />
              <Route path='/folad' element={<Folad/>} />
              <Route path='/nexen' element={<Nexen/>}/>
              <Route path='/painter' element={<Painter/>}/> */}
              <Route path='/puncturetools' element={<PunctureTools />}/>
            </Routes>
            <Footer />
            </Router>
      )

      }
        
      </div>
          
      
        
          
        </>
        
      )
  
}

export default App;
