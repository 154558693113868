import React from 'react'
import punctuer from '../../company-images/2.jpg'
import punctuer1 from '../../tube/11.JPG'
import punctuer2 from '../../TireImages/allgitor/6.png'
import punctuer3 from '../../company-images/5.jpg'
import punctuer4 from '../../company-images/6.jpg'
import punctuer5 from '../../company-images/7.jpg'
import punctuer6 from '../../company-images/8.jpg'
import punctuer7 from '../../company-images/9.jpg'
import punctuer8 from '../../Omar-Image/punctuer-Tools-image/20.JPG'
import punctuer9 from '../../Omar-Image/punctuer-Tools-image/21.JPG'
import punctuer10 from '../../Omar-Image/punctuer-Tools-image/22.JPG'
import punctuer11 from '../../Omar-Image/punctuer-Tools-image/23.JPG'
import punctuer12 from '../../Omar-Image/punctuer-Tools-image/24.JPG'
import punctuer13 from '../../Omar-Image/punctuer-Tools-image/25.JPG'
import Container from 'react-bootstrap/Container'
export default function PantuerTools() {

    return (
        <>
            <section className="section men " id="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-heading">
                                <h2>Our Newest Products</h2>
                                {/* <span>Details to details is what makes Hexashop different from the other themes.</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Container className=''>

                    <div className='slider' >
                        <div className='slide-track'>

                            <div className='slidees'>
                                <img src={punctuer} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer1} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer2} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer3} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer4} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer5} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer6} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer7} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer8} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer9} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer10} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer11} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer12} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={punctuer13} alt="slides" className='image_slider shadow' />
                            </div>



                        </div>

                    </div>



                </Container>

            </section>
        </>
    )
}
