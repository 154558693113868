import React from 'react'
import myIMage from '../../TireImages/allgitor/5.png'
import Tube from '../../tube/5.JPG'
export default function ExplorePart() {
  return (
    <>
     <section className="section" id="explore">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="left-content">
                        <h2>Explore Our Products</h2>
                        <span>At our company, we take pride in providing high-quality car tyres, tubes and puncture tools to our customers. We understand that choosing the right product for your vehicle can be a daunting task, which is why we encourage you to explore our wide range of products to find the perfect fit for your car.</span>
                        <div className="quote">
                            <i className="fa fa-quote-left"></i><p>In addition to tyres, we also offer a range of tubes and puncture tools to ensure that you are prepared for any unexpected situations on the road. Our tubes are made from high-quality materials and are designed to withstand wear and tear, while our puncture tools are easy to use and effective in fixing any tyre-related issues.</p>
                        </div>
                        <p>So why wait? Explore our products today and find the perfect fit for your car. With our wide range of options, competitive prices and expert guidance, we are confident that you will find exactly what you need to keep your vehicle running smoothly on the road.</p>
                        
                        
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="right-content">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="leather">
                                    <h4>Leather Tires</h4>
                                    <span>Latest Collection</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="first-image">
                                    <img src={myIMage} alt="Tire"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="second-image">
                                    <img src={Tube} alt="Tube"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="types">
                                    <h4>Different Types</h4>
                                    <span>Over 304 Products</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
