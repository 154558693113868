import React from 'react'
// import FirstPart from './FirstPart'
import TubePart from './TubePart'
import PantuerTools from './PantuerTools'
import ExplorePart from './ExplorePart'
import SocailPart from './SocailPart'
import Banner from './Banner'
import Tirelatest from './Tirelatest'
export default function Home() {
  return (
    <>
    
    {/* <!-- ***** Main Banner Area Start ***** --> */}
    <div class="main-banner " id="top">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <div class="left-content">
                        <div class="thumb">
                            <Banner />
                            
                        </div>
                    </div>
                </div>
  </div>
        </div>
    </div>
    {/* <!-- ***** Main Banner Area End ***** --> */}

    {/* <!-- ***** Men Area Starts ***** -->  */}
        {/* <FirstPart /> */}
     {/* <!-- ***** Men Area End ***** -->  */}

    {/* <!-- ***** Women Area Starts ***** --> */}
    <TubePart />
    {/* <!-- ***** Women Area Ends ***** --> */}
    <Tirelatest />
    {/* <!-- ***** Kids Area Starts ***** --> */}
    <PantuerTools />
    {/* <!-- ***** Kids Area Ends ***** --> */}

    {/* <!-- ***** Explore Area Starts ***** --> */}
   <ExplorePart />
    {/* <!-- ***** Explore Area Ends ***** --> */}

    {/* <!-- ***** Social Area Starts ***** --> */}
    <SocailPart />
    {/* <!-- ***** Social Area Ends ***** --> */}
   
    </>
  )
}
