import React from 'react'
import Container from 'react-bootstrap/Container'
import punctuer from '../../Omar-Image/punctuer-Tools-image/9.JPG'
import punctuer1 from '../../Omar-Image/punctuer-Tools-image/10.JPG'
import punctuer2 from '../../Omar-Image/punctuer-Tools-image/11.JPG'
import punctuer3 from '../../Omar-Image/punctuer-Tools-image/12.JPG'
import punctuer4 from '../../Omar-Image/punctuer-Tools-image/13.JPG'
import punctuer5 from '../../Omar-Image/punctuer-Tools-image/14.JPG'
import punctuer6 from '../../Omar-Image/punctuer-Tools-image/15.JPG'
import punctuer7 from '../../Omar-Image/punctuer-Tools-image/16.JPG'
import punctuer8 from '../../Omar-Image/punctuer-Tools-image/17.JPG'
import punctuer9 from '../../Omar-Image/punctuer-Tools-image/18.JPG'
import punctuer10 from '../../Omar-Image/punctuer-Tools-image/19.JPG'
import punctuer11 from '../../Omar-Image/punctuer-Tools-image/20.JPG'
import punctuer12 from '../../Omar-Image/punctuer-Tools-image/21.JPG'
import punctuer13 from '../../Omar-Image/punctuer-Tools-image/22.JPG'
import punctuer14 from '../../Omar-Image/punctuer-Tools-image/23.JPG'
import punctuer15 from '../../Omar-Image/punctuer-Tools-image/24.JPG'
import punctuer16 from '../../Omar-Image/punctuer-Tools-image/25.JPG'
import punctuer17 from '../../Omar-Image/punctuer-Tools-image/26.JPG'
import punctuer18 from '../../Omar-Image/punctuer-Tools-image/27.PNG'
import punctuer19 from '../../Omar-Image/punctuer-Tools-image/28.JPG'
import punctuer20 from '../../Omar-Image/punctuer-Tools-image/29.JPG'
import punctuer21 from '../../Omar-Image/punctuer-Tools-image/30.JPG'
import punctuer22 from '../../Omar-Image/punctuer-Tools-image/31.JPG'
import punctuer23 from '../../Omar-Image/punctuer-Tools-image/32.JPG'
import punctuer24 from '../../Omar-Image/punctuer-Tools-image/33.JPG'
import punctuer25 from '../../Omar-Image/punctuer-Tools-image/34.JPG'
import punctuer26 from '../../Omar-Image/punctuer-Tools-image/44.JPG'
import punctuer27 from '../../Omar-Image/punctuer-Tools-image/36.JPG'
import punctuer28 from '../../Omar-Image/punctuer-Tools-image/37.JPG'
import punctuer29 from '../../Omar-Image/punctuer-Tools-image/38.JPG'
import punctuer30 from '../../Omar-Image/punctuer-Tools-image/39.JPG'
import punctuer31 from '../../Omar-Image/punctuer-Tools-image/40.JPG'
import punctuer32 from '../../Omar-Image/punctuer-Tools-image/41.JPG'
import punctuer33 from '../../Omar-Image/punctuer-Tools-image/42.JPG'
import punctuer34 from '../../Omar-Image/punctuer-Tools-image/43.JPG'
export default function Passenger() {


    return (
        <>
            <Container>
                <div className='row'>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-211</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer1} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-221</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer2} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer3} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer4} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer5} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer6} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer7} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer8} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer9} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer10} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer11} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer12} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer13} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer14} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer15} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer16} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer17} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer18} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer19} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer20} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer21} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer22} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer23} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer24} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer25} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer26} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer27} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer28} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer29} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer30} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer31} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer32} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer34} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3' >
                        <div className='cart'>
                            <div className='cart-body'>
                                <img src={punctuer33} alt="Punctur Tools" className='w-100' />
                            </div>
                            <div className='cart-footer'>
                                <h4 className='my-2 mx-2'>PT-232</h4>
                            </div>
                        </div>
                    </div>

                </div>

            </Container>

        </>
    )
}
