import React from 'react'
import thomas from '../../company-images/logo/download (1).jpg';
import noman from '../../company-images/logo/download.png'
export default function OurTeam() {
  
  return (
    
    <>
        <section className="our-team">
        <div className="">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading">
                        <h2>Customers Review</h2>
                        
                    </div>
                </div>
                
                
                <div className=''>
                    <div className='feadback'>
                    <div className='row '>
                        
                        <div className='col-lg-4  feadback-cart my-1'>
                            
                            <div className='feadback-span mx-5  '>
                                <div className='image-avatar text-center'>
                                <img src={thomas} className='my-3' alt="thomas" />
                                </div>
                            <span className='text-white text-center my-5'>"The only company we really trust with our 
                            products in Afghanistan is FHK for sure. This statement comes 
                            from my experience"</span>
                            </div>
                            <div className='feadback-h2'>
                            <h4 className='text-white text-center'>"Thomas Gibbs"</h4>
                            <h5 className='text-white text-center'>'The Balkan Tyre Production Company'</h5>
                            </div>
                        </div>
                        <div className='col-lg-4  feadback-cart my-1 '>
                            
                            <div className='feadback-span my-1'>
                            <div className='image-avatar text-center'>
                                <img src={thomas} className='my-1' alt="thomas" />
                                </div>
                            <span className='text-white text-center my-5'>"FHK is the only company that can provide 
                            our procurement requirements on time, on quality and from trusted producers"<br/></span>
                            </div>
                            <div className='feadback-h2 my-3'>
                            <h4 className='text-white text-center'>"Haji Noman Bashiri"</h4>
                            <h5 className='text-white text-center'>'Bashiri Group of companies'</h5>
                            </div>
                        </div>
                        <div className='col-lg-4  feadback-cart my-3    '>
                            
                            <div className='feadback-span my-1'>
                            <div className='image-avatar text-center'>
                                <img src={noman} className='my-1' alt="thomas" />
                                </div>
                            <span className='text-white text-center my-5'>"I have been in the tubes and puncture tools
                             business for a decade but I've finally came to the conclusion that this is the
                              company I want to stay a customer to for as long as possible"
                              </span>
                            </div>
                            <div className='feadback-h2'>
                            <h4 className='text-white text-center '>"Haji Ahmad"</h4>
                            <h5 className='text-white text-center'>'A shopkeeper in Helmand'</h5>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                    </div>
                    
                </div>
  
                
                
            
     </div>  
       </div> 
    </section>
    </>
  )
}
