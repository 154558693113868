
import React from 'react'
import {Carousel} from 'react-bootstrap'
export default function Banner() {
   
  return (
   <>
   
                                <Carousel className='bannerleft '>
                                   
                                               
                                   
                                   <Carousel.Item className='bannerimage'>
                                        
                                   </Carousel.Item >
                                   <Carousel.Item className='bannerimage1'>
                                        
                                   </Carousel.Item>
                                   <Carousel.Item className='bannerimage2'>
                                      
                                   </Carousel.Item>
                                   <Carousel.Item className='bannerimage3'>
                                       
                                   </Carousel.Item>
                                   <Carousel.Item className='bannerimage4'>
                                       
                                   </Carousel.Item>
                                   <Carousel.Item className='bannerimage5'>
                                       
                                   </Carousel.Item>
                                   <Carousel.Item className='bannerimage6'>
                                       
                                   </Carousel.Item>
                            
                                </Carousel>
                             
                            
   
   </>
  )
}
