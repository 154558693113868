import React from 'react'
import Slider from 'react-slick'
import tire from '../../Omar-Image/logo-n/2024_01_31_11_40_IMG_4136.JPG'
import tire1 from '../../Omar-Image/logo-n/2024_01_31_11_44_IMG_4141.PNG'
import tire2 from '../../Omar-Image/logo-n/2024_01_31_11_41_IMG_4137.JPG'

import tire4 from '../../Omar-Image/logo-n/2024_01_31_11_43_IMG_4140.PNG'
import tire5 from '../../Omar-Image/logo-n/2024_01_31_11_45_IMG_4143.JPG'

import tire7 from '../../Omar-Image/logo-n/2024_01_31_12_01_IMG_4145.JPG'
import Container  from 'react-bootstrap/Container';
export default function Tirelatest() {
   
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <>
    <section className='men'>
    <Container>
    <div className="row">
                <div className="col-lg-6">
                    <div className="section-heading">
                        <h2>Our Tire Partners</h2>
                        <span>we have all types of Tire.</span>
                    </div>
                </div>
            </div>
    <div className='row'>
        
        <Slider {...settings}>
         
                    <div className='col-lg-12'>
                        <div className='carousell'>
                            <div className='carousell-image'>
                                <img src={tire} className="shadow" alt="tie" />
                            </div>
                            <div className='carousell-span'>
                                <span>TL-122</span>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-12'>
                        <div className='carousell'>
                            <div className='carousell-image'>
                                <img src={tire1} className="shadow" alt="tie" />
                            </div>
                            <div className='carousell-span'>
                                <span>TL-123</span>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-12'>
                        <div className='carousell'>
                            <div className='carousell-image'>
                                <img src={tire2} className="shadow" alt="tie" />
                            </div>
                            <div className='carousell-span'>
                                <span>TL-124</span>
                            </div>
                        </div>

                    </div>
                    
                    <div className='col-lg-12'>
                        <div className='carousell'>
                            <div className='carousell-image'>
                                <img src={tire4} className="shadow" alt="tie" />
                            </div>
                            <div className='carousell-span'>
                                <span>TL-126</span>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-12'>
                        <div className='carousell'>
                            <div className='carousell-image'>
                                <img src={tire5} className="shadow" alt="tie" />
                            </div>
                            <div className='carousell-span'>
                                <span>TL-127</span>
                            </div>
                        </div>

                    </div>
                   
                    <div className='col-lg-12'>
                        <div className='carousell'>
                            <div className='carousell-image'>
                                <img src={tire7} className="shadow" alt="tie" />
                            </div>
                            <div className='carousell-span'>
                                <span>TL-129</span>
                            </div>
                        </div>

                    </div>
          
        </Slider>
      </div>
    </Container>
    
    </section>
    </>
  )
}
