import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
export default function Contact() {
  const [formData,setFormData]=useState({
    name:'',
    email:'',
    message:''
  });
  const handleSubmit=async(e)=>{
    e.preventDefault();
    try{
      await axios.post("http://127.0.0.1:8000/contact/",formData);
      alert('Data Submitted successfully');
      setFormData({
        name:'',
        email:'',
        message:'',
      });
    } catch(error){
      console.log('Error submitting data:',error);
    }

  };
  const handleChange=(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value});
  }
  return (
    <>
    {/* <!-- ***** Main Banner Area Start ***** --> */}
    <div class="page-heading about-page-heading" id="top">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="inner-content">
                        <h2>Contact Us</h2>
                        <span>You can easily contact with us by sending message.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- ***** Main Banner Area End ***** --> */}

    {/* <!-- ***** Contact Area Starts ***** --> */}
    <div class="contact-us">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                <div class="subscribe">
                  <div class="container">
            <div class="row">
                
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-8">
                          <div className="container">
                            <div className="row">
                              <div className="col-6">
                              <ul>
                                
                                <li> Main office:<br/><span>+93772272931</span></li>
              
                                <li>Kabul branch Location:<br/><span> de-hane-bagh squre  kabul afghanistan</span><br></br></li>
                                <li>Phone:<br/><span>+93744929333 , +93764068035</span></li>
                                
                                
                            </ul>
                              </div>
                              <div className="col-6">
                                <ul>
                                <li>Ghazni branch Location 1:<br/><span>ghazni kabul kandahar street fayzi market</span><br></br></li>
                                <li>Phone:<br/><span>+93773629020</span></li>
                                <li>Ghazni branch Location 2:<br/><span> ghazni kabul kandahar street under speen zar hotel</span><br></br></li>
                                <li>Phone:<br/><span>+93799800844</span></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                            
                        </div>
                        <div class="col-4">
                            <ul>
                                <li>Work Hours:<br/><span>07:30 AM - 5:30 PM Daily</span></li>
                                <li>Email:<br/><Link to="Fazalhabibkarwan@gmail.com"><span>Fazalhabibkarwan@gmail.com</span></Link></li>
                                <li>Social Media:<br/><span><Link to="https://www.facebook.com/profile.php?id=61555693007334&mibextid=LQQJ4d">Facebook</Link>,  <Link to="https://wa.me/0093772272931">WhatsApp</Link></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </div>
                <div class="col-lg-6">
                    <div class="section-heading">
                        <h2>Say Hello. Don't Be Shy!</h2>
                        <span></span>
                    </div>
                    <form id="contact" action="" method="post" onSubmit={handleSubmit}>
                        <div class="row">
                          <div class="col-lg-6">
                            <fieldset>
                              <input name="name" type="text" id="name" placeholder="Your name" required="" onChange={handleChange} value={formData.name}/>
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input name="email" type="text" id="email" placeholder="Your email" required="" onChange={handleChange} value={formData.email}/>
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <textarea name="message" rows="6" id="message" placeholder="Your message" required="" onChange={handleChange} value={formData.message}></textarea>
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset/>
                              <button type="submit" id="form-submit" class="main-dark-button"><i class="fa fa-paper-plane"></i></button>
                          </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- ***** Contact Area Ends ***** --> */}


   
    </>
  )
}
