import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Logo from '../company-images/logo/new new.png'
import {Link} from 'react-router-dom'
// import  NavDropdown  from 'react-bootstrap/NavDropdown'
export default function HeaderMenu() {
  return (
    <>
    {/* <!-- ***** Header Area S/art ***** --> */}
    <header className="header-area header-sticky">
    <Navbar expand="lg" className="bg-body-tertiary  ">
        <Container>
        <Navbar.Brand expand="lg" className="bg-body-tertiary main-nav"> {/* <!-- ***** Logo Start ***** --> */}
                        <Link as={Link} to="/" className="">
                            <img src={Logo} className='logo' alt='Logo'/>
                        </Link>
                        {/* <!-- ***** Logo End ***** --> */}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='nav'>
          <Nav className="me-auto ">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            
            {/* <NavDropdown title="Tire Brands"  id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/destone">Deestone</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/lionlord">Lionlord</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/leao">Leao</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/austone">Austone</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/linglong">Linglong</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Tube Brands" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link}  to="/dongah">Dongah</NavDropdown.Item>
              
              <NavDropdown.Item as={Link}  to="/nexen">Nexen</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/sobek">Sobek</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/folad">Folad</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/painter">Painter</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/power">Power</NavDropdown.Item>
              <NavDropdown.Item as={Link}  to="/service">Service</NavDropdown.Item> */}
            {/* </NavDropdown> */}
            <Nav.Link as={Link}  to="/puncturetools">Puncture Tools</Nav.Link>
            <Nav.Link as={Link}  to="/about">About Us</Nav.Link>
            <Nav.Link as={Link}  to="/contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar>
    </header>
    {/* <!-- ***** Header Area End ***** --> */}
    </>
  )
}
