import React from 'react'
import { Link } from 'react-router-dom'
import whiteLogo from '../company-images/logo/new logo white.png'
export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="first-item">
                        
                        <div className="logo">
                            <img src={whiteLogo} alt="Fazal Hiabib Karwan"/>
                        </div>
                        <ul>
                            <li><Link to="n">de-hane bagh squre afghan super market Kabul Afghanistan</Link></li>
                            <li><Link to="fazalhabibkarwan@gmail.com">fazalhabibkarwan@gmail.com</Link></li>
                            <li><Link to="n">+93-77-227-2931 </Link></li>
                            <li><Link to="n">+93-79-963-3456 </Link></li>
                        </ul>
                    </div>
                </div>
                {/* <div className="col-lg-3">
                    <h4>Tire Brands</h4>
                    <ul>
                        <li><Link to="/destone">Deestone</Link></li>
                        <li><Link to="/lionlord">Lionlord</Link></li>
                        <li><Link to="/leao">Leao</Link></li>
                        <li><Link to="/austone">Austone</Link></li>
                        <li><Link to="/linglong">Linglong</Link></li>
                    </ul>
                </div>
                <div className="col-lg-3">
                    <div className='row'>
                    <h4 className='text-start'>Tube Brands</h4>
                    <ul className='col-lg-4'>
                        <li><Link to="/dongah">Dongah</Link></li>
                        
                        <li><Link to="/power">Power</Link></li>
                        <li><Link to="/nexen">Nexen</Link></li>
                        <li><Link to="/painter">Painter</Link></li>
                        
                    </ul>
                    <ul className='col-lg-6 '>
                    <li><Link to="/service">Service</Link></li>
                        <li><Link to="/sobek">Sobek</Link></li>
                        <li><Link to="/folad">Folad</Link></li>
                    </ul>
                </div>
                    </div> */}
                    
                <div className="col-lg-6">
                    <h4>Help &amp; Information</h4>
                    <ul>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        
                    </ul>
                </div>
                <div className="col-lg-12">
                    <div className="under-footer">
                        <p>Copyright © 2000 Fazal Habib Karwan Group of Companies., Ltd. All Rights Reserved.</p>
                        
                        
                        <ul>
                            <li><Link to="https://www.facebook.com/profile.php?id=61555693007334&mibextid=LQQJ4d"><i className="fa fa-facebook"></i></Link></li>
                            
                            
                            <li><Link to="https://wa.me/0093772272931"><i className="fa fa-whatsapp"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}
