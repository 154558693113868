import React from 'react'
import about from '../../Omar-Image/logo-n/fazalhabib.jpg'
import {Link} from 'react-router-dom'
export default function AboutStart() {
   
  return (
    <>
     {/* <!-- ***** About Area Starts ***** --> */}
     <div className="about-us">
        <div className="container">
            <div className="row">
                
                            <>
                            
                
                <div className="col-lg-6" >
                   
                
                            <div className="right-content" >
                            <h4>Fazal Habib Karwan</h4>
                            <span>In 1995 ALHARAMAIN company was established in Ghazni city by leader ship of Mohammad Hashim Mohammadi.
                                This company growth was too fast and full of success.
                                At the first year of establishing we were just a local company and buying lubricant and tires from local market.
                                In 1998 we have started importing tires from China for first time.
                                And imports of tires, tubes and lubricants coutinued to 2002.
                                In first days of 2002 we had established our first sales site in kabul city.
                                The growth of ALHARAMAIN company was very fast and full of success.
                                In the long run we have established FAZAL HABIB KARWAN in invested for tire business and ALHARAMAIN investment is for inner tubes, tire repairs and all tire repairing equipments.
                            </span>
                            
                            <ul>
                                <li><Link to="https://www.facebook.com/profile.php?id=61555693007334&mibextid=LQQJ4d"><i className="fa fa-facebook"></i></Link></li>
                                <li><Link to="https://wa.me/0093772272931"><i className="fa fa-whatsapp"></i></Link></li>
                            </ul>
                        </div>
                      
                    
                </div>
                <div className="col-lg-6">
                    
                    <div className="left-image">
                        <img src={about} alt="About"/>
                    </div>
                </div>
                
                </>
                
            
                    
                </div>
        </div>
    </div>
    {/* <!-- ***** About Area Ends ***** --> */}
    </>
  )
}
