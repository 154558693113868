import React from 'react'
import tube from '../../Omar-Image/logo-n/4.JPG'
import tube1 from '../../Omar-Image/logo-n/2024_01_31_12_01_IMG_4149.JPG'
import tube2 from '../../Omar-Image/logo-n/NEXENjpg.jpg'
// import tube3 from '../../Omar-Image/logo-n/6.JPG'
import tube4 from '../../Omar-Image/logo-n/7.PNG'
// import tube5 from '../../Omar-Image/logo-n/8.PNG'

import Slider from 'react-slick'
import Container from 'react-bootstrap/Container'
export default function TubePart() {
    
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <>
        <section className='men'>
        <Container>
        <div className="row">
                <div className="col-lg-6">
                    <div className="section-heading">
                        <h2>Our Tube partners</h2>
                        <span>we have all types of tube.</span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <Slider {...settings}>
                   
                           <div className="col-lg-12">
                           <div className='carousell'>

                            <div className='carousell-image'>
                                <img src={tube} className='shadow' alt="" />
                            </div>
                            <div className="carousell-span">
                                <span  className="my-5">TL-321</span>
                            </div>
                            </div>
                           </div>
                           <div className="col-lg-12">
                           <div className='carousell'>

                            <div className='carousell-image'>
                                <img src={tube1} className='shadow' alt="" />
                            </div>
                            <div className="carousell-span">
                                <span  className="my-5">TL-322</span>
                            </div>
                            </div>
                           </div>
                           <div className="col-lg-12">
                           <div className='carousell'>

                            <div className='carousell-image'>
                                <img src={tube2} className='shadow' alt="" />
                            </div>
                            <div className="carousell-span">
                                <span  className="my-5">TL-323</span>
                            </div>
                            </div>
                           </div>
                           {/* <div className="col-lg-12">
                           <div className='carousell'>

                            <div className='carousell-image'>
                                <img src={tube3} className='shadow' alt="" />
                            </div>
                            <div className="carousell-span">
                                <span  className="my-5">TL-324</span>
                            </div>
                            </div>
                           </div> */}
                           <div className="col-lg-12">
                           <div className='carousell'>

                            <div className='carousell-image'>
                                <img src={tube4} className='shadow' alt="" />
                            </div>
                            <div className="carousell-span">
                                <span  className="my-5">TL-325</span>
                            </div>
                            </div>
                           </div>
                           {/* <div className="col-lg-12">
                           <div className='carousell'>

                            <div className='carousell-image'>
                                <img src={tube5} className='shadow' alt="" />
                            </div>
                            <div className="carousell-span">
                                <span  className="my-5">TL-326</span>
                            </div>
                            </div>
                           </div> */}
                           

                           
                                
                          
                   
                </Slider>

            </div>
        </Container>
   
        </section>
    </>

    )
}
