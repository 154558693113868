import React from 'react'
import brand from '../../Omar-Image/logo-n/4.JPG'
import brand1 from '../../Omar-Image/logo-n/2024_01_31_11_40_IMG_4136.JPG'
import brand2 from '../../Omar-Image/logo-n/2024_01_31_11_41_IMG_4137.JPG'
import brand3 from '../../Omar-Image/logo-n/5.JPG'
import brand4 from '../../Omar-Image/logo-n/6.JPG'
import brand5 from '../../Omar-Image/logo-n/2024_01_31_11_43_IMG_4140.PNG'
import brand6 from '../../Omar-Image/logo-n/2024_01_31_11_44_IMG_4141.PNG'
import brand7 from '../../Omar-Image/logo-n/7.PNG'
import brand8 from '../../Omar-Image/logo-n/8.PNG'
import brand9 from '../../Omar-Image/logo-n/2024_01_31_12_01_IMG_4145.JPG'
import brand10 from '../../Omar-Image/logo-n/5.PNG'
import brand11 from '../../Omar-Image/logo-n/2024_01_31_12_01_IMG_4148.JPG'
import brand12 from '../../Omar-Image/logo-n/2024_01_31_12_01_IMG_4149.JPG'
import brand13 from '../../Omar-Image/logo-n/2024_01_31_12_01_IMG_4150.JPG'
export default function SocailPart() {

    return (
        <>
            <section className="section men" id="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>Our Brands</h2>
                                <span>Our Company import Tire, Tube and Puncture tools from different companies.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className='slider' >
                        <div className='slide-track'>

                            <div className='slidees'>
                                <img src={brand} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand1} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand2} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand3} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand4} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand5} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand6} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand7} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand8} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand9} alt="slides" className='image_slider shadow' />
                            </div>

                            <div className='slidees'>
                                <img src={brand10} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={brand11} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={brand12} alt="slides" className='image_slider shadow' />
                            </div>
                            <div className='slidees'>
                                <img src={brand13} alt="slides" className='image_slider shadow' />
                            </div>



                        </div>

                    </div>



                </div>
            </section>
        </>
    )
}
