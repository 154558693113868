import React from 'react'
import AboutStart from './AboutStart'
import OurTeam from './OurTeam'
// import Services from './Services'
export default function About() {
  return (
    <>
    
    {/* <!-- ***** Main Banner Area Start ***** --> */}
    <div className="page-heading about-page-heading" id="top">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="inner-content">
                        <h2>About Our Company</h2>
                        <span>Know About The Fazal Habib Karwan Group of Compaies</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- ***** Main Banner Area End ***** --> */}

    <AboutStart />

     {/* <!-- ***** Our Team Area Starts ***** --> */}

    {/* <!-- ***** Our Team Area Ends ***** --> */}
        <OurTeam />
    {/* <!-- ***** Services Area Starts ***** --> */}
        {/* <Services /> */}
    {/* <!-- ***** Services Area Ends ***** --> */}

    </>
  )
}
