import React from 'react'
import Passenger from './Passenger'
export default function PunctureTools() {
  return (
    <>
     <section className="section women" id="">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="section-heading">
                        <h2>Puncture Tools</h2>
                        {/* <span>Details to details is what makes Hexashop different from the other themes.</span> */}
                    </div>
                </div>
            </div>
        </div>
        <Passenger />
    </section>
    </>
  )
}
